<template>
  <ul
    class="subnav"
    ref="subnav"
  >
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'sub-nav',

  inject: {
    handleNav: {}
  }
}
</script>
